import { useEffect, useState } from "react";
import SNavBarComputer from "./SNavBarComputer";
import SNavBarMovil from "./SNavBarMovil";
import Device from "../config/device.config";
// import userModel from "../models/user.model";

const SNavBar = (props) => {

    const [data, setData] = useState({
        isTablet: null,
        isMovil: null,
        isDesktop: null,
    });

    useEffect(() => {

        let data = Device.getData()

        setData(data);

    }, []);

    return (
        <>
            {data.isDesktop ?
                <SNavBarComputer />
                :
                <SNavBarMovil />
            }
        </>
    );
}

export default SNavBar;