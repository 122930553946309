import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../config/colors.config.js";
import {
    Row,
    Col,
    Label,
    DropdownItem,
} from "reactstrap";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import img from "../assets/image/background/003.jpg"

class Mision extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }

    
    componentDidMount() {
        this.props.isDesktopDevice ?
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`

    }

    render() {
        return (
            <>
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />



                <div
                    style={{
                        marginTop: "80px",
                        marginRight: this.props.isDesktopDevice ? "10%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "10%" : "5px",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        borderRadius: "8px"
                    }}
                >

                    <Row >
                        <Col
                            style={{
                                marginTop: "50px",
                            }}
                        >
                            <center>
                                <Label
                                    style={{
                                        fontSize: "56px",
                                        color: colors.verde_1,

                                        fontWeight: "bold",

                                    }}
                                >
                                    TZEDAKÁ
                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "10px",
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: this.props.isDesktopDevice ? "25px" : "25px",
                                        color: colors.verde_1,
                                        // color:"#FBB23C",
                                        fontWeight: "bold",

                                    }}
                                >
                                    ¡ESTAMOS CONSTRUYENDO ALGO FANTÁSTICO!

                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: this.props.isDesktopDevice ? "10px" : "30px",

                        }}
                    >

                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: "30px",
                                        color: colors.verde_1,
                                        // color:"#F6B855",
                                        fontWeight: "bold",
                                        marginBottom: "50px",

                                    }}
                                >
                                    Espéralo
                                </Label>
                            </center>
                        </Col>
                    </Row>

                </div>

                <div
                    style={{
                        marginTop: "20px",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        marginRight: this.props.isDesktopDevice ? "10%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "10%" : "5px",
                        borderRadius: "8px"
                    }}
                >


                    <Row

                    >
                        <Col
                            md={this.props.isDesktopDevice ? "" : "5"}
                        >
                            <div
                                style={{
                                    marginRight: "20px",
                                    marginLeft: "20px",
                                    marginTop: "70px",
                                    marginBottom: "20px"
                                }}
                            >
                                <Row>
                                    <Col
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "28px"
                                            }}
                                        >
                                            Nuestra Misión
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                marginTop: "10px"
                                            }}
                                        >
                                            CRÉDITOS apoyando a FAMILIAS.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <center>
                                <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets6.lottiefiles.com/packages/lf20_j7dhg1hl.json"
                                    speed={0.5}
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        marginTop: "40px"
                                    }}
                                />
                            </center>
                        </Col>

                    </Row>
                    <DropdownItem divider />
                    {/* <Row><center><div style={{height:"10px",backgroundColor:"red", width:"100%"}} ></div></center></Row> */}
                    <Row
                        style={{
                            marginBottom: "100px"
                        }}
                    >
                        {
                            this.props.isDesktopDevice ?
                                <>
                                    <Col
                                        md={this.props.isDesktopDevice ? "" : "5"}
                                        style={{

                                        }}
                                    >
                                        <center>
                                            <lottie-player
                                                autoplay
                                                loop
                                                mode="normal"
                                                src="https://assets6.lottiefiles.com/packages/lf20_22mjkcbb.json"
                                                speed={0.5}
                                                style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    marginTop: "40px"
                                                }}
                                            ></lottie-player>
                                        </center>
                                    </Col>
                                    <Col
                                        md={this.props.isDesktopDevice ? "" : "5"}
                                        style={{
                                            backgroundColor: "",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                marginTop: "80px",
                                                marginBottom: "20px"
                                            }}
                                        >
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "28px"
                                                        }}
                                                    >
                                                        Nuestra Visión
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: "18px",
                                                            marginTop: "10px"
                                                        }}
                                                    >
                                                        Otorgar CRÉDITOS con INTERESÉS JUSTOS al alcance de TODOS.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </>
                                :
                                <>
                                    <Col
                                        md={this.props.isDesktopDevice ? "" : "5"}
                                        style={{
                                            backgroundColor: "",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "20px",
                                                marginLeft: "20px",
                                                marginTop: "80px",
                                                marginBottom: "20px"
                                            }}
                                        >
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "28px"
                                                        }}
                                                    >
                                                        Nuestra Visión
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: "18px",
                                                            marginTop: "10px"
                                                        }}
                                                    >
                                                        Otorgar CRÉDITOS con INTERESÉS JUSTOS al alcance de TODOS.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col

                                        style={{

                                        }}
                                    >
                                        <center>
                                            <lottie-player
                                                autoplay
                                                loop
                                                mode="normal"
                                                src="https://assets6.lottiefiles.com/packages/lf20_22mjkcbb.json"
                                                speed={0.5}
                                                style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    marginTop: "40px"
                                                }}
                                            ></lottie-player>
                                        </center>
                                    </Col>

                                </>
                        }
                    </Row>
                </div>

            </>
        );
    }

}

export default Mision;