const Colors = {
    verde_1 : "#0DB02B",
    verde_2 : "#61BF1A",
    verde_3 : "#8FD400",
    azul : "#00669E",
    azul_1 : "#4799C7",
    azul_link : "#0563C1",
    blanco: "#FFFFFF",
    negro: "#000",
    rojo : "red",
}

// const Colors = {
//     verde_1: "#00669E",
//     verde_2: "#61BF1A",
//     verde_3: "#8FD400",
//     azul: "#0DB02B",
//     azul_link: "#0563C1",
//     blanco: "#FFFFFF",
//     negro: "#000",
//     rojo: "red",
// }

export default Colors;