const getWidthScreen = () => {
    return window.innerWidth;
}



const getHeightScreen = (percentage) => {

    let width = window.innerHeight;

    if (percentage === undefined) return width;

    return width * (percentage/100)
}

const Screen = {
    getWidthScreen,
    getHeightScreen
}

export default Screen;