import React, { useState } from 'react';
import {
    Navbar,
    Nav,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Col,
    DropdownItem,
} from 'reactstrap';

import colors from "../config/colors.config.js";
import {
    Apartment,
    AttachMoney,
    Close,
    ContactMail,
    Home,
    Info,
    Menu,
    Visibility,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import components from '../tools/components.tool.js';
import { QuestionMark } from '@mui/icons-material';



const SNavBarMovil = () => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);

    return (

        <div

        >
            <Navbar
                expand="md"
                style={{
                    backgroundColor: colors.azul,
                    height: "62px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 3
                }}
            >
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: colors.azul, borderColor: colors.azul, boxShadow: "none", color: colors.azul, fontWeight: "bold" }}
                    onClick={open}
                >
                    <Menu style={{ color: colors.blanco }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: "#fff",
                        maxWidth: "80%"
                    }}
                >
                    <OffcanvasHeader
                        toggle={open}
                        close={
                            <IconButton
                                onClick={open}
                                style={{ backgroundColor: "#F4F4F4", color: colors.azul }}
                            >
                                <Close />
                            </IconButton>
                        }
                    >
                        <label
                            style={{
                                color: colors.azul,
                                fontWeight: "bold",
                                fontSize: "28px",
                                fontSize: 24
                            }}
                        >
                            <Menu /> Menu
                        </label>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Nav vertical style={{ marginTop: -10 }}  >
                            <Row>
                                <Col>
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#init";
                                            open();
                                        }}
                                    >
                                        <Home style={{ marginRight: 7 }} />Inicio
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,

                                        }}
                                        onClick={() => {
                                            window.location.href = "/#products";
                                            open();
                                        }}
                                    >
                                        <AttachMoney style={{ marginRight: 7 }} />InicioProductos
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#mision";
                                            open();
                                        }}
                                    >
                                        <Visibility style={{ marginRight: 3 }} /> Misión Y Visión
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "#who-is";
                                            open();
                                        }}
                                    >
                                        <QuestionMark style={{ marginRight: 7 }} />Quienes somos
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#sucursales";
                                            open();
                                        }}
                                    >
                                        <Apartment style={{ marginRight: 3 }} /> Sucursales
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#contact";
                                            open();
                                            components.sleep(1).then(() => {
                                                components.focusElement("asunto")
                                            });
                                        }}
                                    >
                                        <ContactMail style={{ marginRight: 3 }} /> Contacto
                                    </Button>
                                </Col>
                            </Row>
                            <DropdownItem divider />
                            <Row>
                                <Col>
                                    <Button
                                        style={{
                                            backgroundColor: colors.blanco,
                                            color: colors.azul,
                                            borderColor: colors.blanco,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/aviso-de-privacidad";
                                        }}
                                    >
                                        <Info style={{ marginRight: 7 }} /> Aviso de Privacidad
                                    </Button>
                                </Col>
                            </Row>
                        </Nav>

                    </OffcanvasBody>

                </Offcanvas>
            </Navbar>
        </div >


    );
}

export default SNavBarMovil;