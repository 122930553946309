import React, {
    useEffect, useState,
    //useState 
} from 'react';
import {
    Navbar,
    Nav,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row,
    Col
} from 'reactstrap';
import colors from "../config/colors.config.js";
import imgLogoTzedaka from "../assets/image/logo/logo_2.png";
import imgLogoTzedaka_2 from "../assets/image/logo/logoTzedaka.png";
import { IconButton } from '@material-ui/core';
import {
    YouTube,
    Facebook,
    Instagram,
    Twitter,
    Room,
} from "@material-ui/icons";



const NavBarComputer = (props) => {

    // const [style] = useState({
    //     backgroundColor: colors.blanco,
    //     color: colors.azul,
    //     fontWeight: "bold",
    //     border: "none",
    //     boxShadow: "none"
    // });

    // const [styleIcon] = useState({
    //     marginTop: "-5px",
    //     marginRight: "5px",
    //     color: colors.azul
    // });
    // const [styleIconSecondary] = useState({
    //     marginTop: "-5px",
    //     marginRight: "5px",
    //     color: colors.blanco
    // });
    const [isOpenModal, openModal] = useState(false);
    const [isOpenModalSecundario, openModalSecundario] = useState(false);
    const [isOpenModalMap, openModalMap] = useState(false);

    const [isButtonHover, setButtonHover] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
    });



    useEffect(() => {

    }, []);

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "100",
                    fontFamily: "monospace"
                }}
            >
                <Navbar
                    expand="md"
                    style={{

                        backgroundColor: colors.verde_1,
                        height: "62px"
                    }}>
                    <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                       
                        <img
                            src={imgLogoTzedaka}
                            alt="No imagen"
                            height={40}
                            style={{
                                // marginRight:"5px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                openModal(true);
                            }}
                        />
                        <Button
                            style={{
                                backgroundColor: colors.verde_1,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[0] ? `transparent transparent ${colors.blanco} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: true,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/";
                            }}
                        >

                            Inicio
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.verde_1
,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[1] ? `transparent transparent ${colors.blanco} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: true,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/productos";
                            }}
                        >

                            Productos
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.verde_1
,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[2] ? `transparent transparent ${colors.blanco} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: true,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/quienes-somos";
                            }}
                        >
                            Quienes somos
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.verde_1
,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[4] ? `transparent transparent ${colors.blanco} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: true,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/contacto";

                            }}
                        >

                            Contacto
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.verde_1
,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[3] ? `transparent transparent ${colors.blanco} transparent` : "transparent",
                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: true,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {
                                window.location.href = "/mision-y-vision";
                            }}
                        >

                            Misión Y Visión
                        </Button>
                        

                        <Button
                            style={{
                                backgroundColor: colors.verde_1,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[5] ? `transparent transparent ${colors.blanco} transparent` : "transparent",

                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: true,
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                });
                            }}
                            onClick={() => {

                                window.location.href = "/solicitud-credito";
                            }}
                        >

                          
                                Solicitud de Crédito
                        </Button>

                        <Button
                            style={{
                                backgroundColor: colors.verde_1
,
                                color: colors.blanco,
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: "0px 0px 0px 0px",
                                borderColor: isButtonHover[6] ? `transparent transparent ${colors.blanco} transparent` : "transparent",

                                fontSize: "18px"
                            }}
                            onMouseEnter={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                    6: true
                                });
                            }}
                            onMouseLeave={() => {
                                setButtonHover({
                                    0: false,
                                    1: false,
                                    2: false,
                                    3: false,
                                    4: false,
                                    5: false,
                                    6: false
                                });
                            }}
                            onClick={() => {

                                window.location.href = "/aviso-de-privacidad";
                            }}
                        >
                            
                            Aviso de privacidad
                        </Button>

                    </Nav>

                    <Row>
                        <Col
                            style={{
                                marginRight: "50px"
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.facebook.com/", '_blank');
                                }}
                                title="Facebook"
                            >
                                <Facebook
                                    style={{
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.instagram.com/", '_blank');
                                }}
                                title="Instagram"
                            >
                                <Instagram
                                    style={{
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.youtube.com/", '_blank');
                                }}
                                title="YouTube"
                            >
                                <YouTube
                                    style={{
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open("https://www.twitter.com/", '_blank');
                                }}
                                title="Twitter"
                            >
                                <Twitter
                                    style={{
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    openModalMap(true)
                                }}
                                title="Ver mapa"
                            >
                                <Room
                                    style={{
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>
                        </Col>
                    </Row>

                </Navbar>
            </div>
            <Modal
                isOpen={isOpenModal}
                size="lg"
            >
                <ModalHeader
                    toggle={() => {
                        openModal(false);
                    }}
                    style={{
                        backgroundColor: colors.azul,
                        color: colors.blanco
                    }}
                >
                    <Label
                        style={{
                            fontSize: "24px",
                        }}
                    >
                        ¿SABíAS QUE?
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col
                            md="4"
                            style={{
                                // backgroundColor: "blue"
                            }}
                        >
                            <center>
                                <img
                                    src={imgLogoTzedaka}
                                    alt="No imagen"
                                    height={200}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        openModalSecundario(true);
                                    }}
                                />
                            </center>
                        </Col>
                        <Col
                            md="8"
                            style={{
                                textAlign: "center",
                                // backgroundColor: "red"
                            }}
                        >
                            <center>
                                <p style={{ textAlign: "justify", marginRight: "30px", marginTop: "20px" }}>Tzedaká es una palabra hebrea que significa solidaridad. Tiene su raíz en la palabra tzedek, justicia. Así, <strong style={{ fontWeight: "bold" }}> Tzedaká significa Justicia y Solidaridad.</strong> Es la restitución del derecho de un semejante a vivir con dignidad.</p>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "20px"
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        color: colors.azul
                                    }}
                                >
                                    En Tzedaká otorgamos créditos justos para todos
                                </Label>
                            </center>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            openModal(false);
                        }}
                        style={{
                            backgroundColor: colors.verde_2,
                            boxShadow: "none",
                            borderColor: colors.verde_3,
                            color: colors.b,
                            fontWeight: "bold"
                        }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalSecundario}
            // size="lg"
            >
                <ModalHeader
                    toggle={() => {
                        openModalSecundario(false);
                    }}
                    style={{
                        backgroundColor: colors.azul,
                        color: colors.blanco
                    }}
                >
                    <Label
                        style={{
                            fontSize: "24px",
                        }}
                    >
                        ¿Ya conocías nuestro logo?
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col
                            style={{
                                // backgroundColor: "blue"
                            }}
                        >
                            <center>
                                <img
                                    src={imgLogoTzedaka_2}
                                    alt="No imagen"
                                    height={200}
                                />
                            </center>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            openModalSecundario(false);
                        }}
                        style={{
                            backgroundColor: colors.verde_2,
                            boxShadow: "none",
                            borderColor: colors.verde_3,
                            color: colors.b,
                            fontWeight: "bold"
                        }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalMap}
                size="xl"
            // style={{
            //     width:"1500px"
            // }}
            >
                <ModalHeader
                    toggle={() => { openModalMap(false) }}
                    style={{
                        backgroundColor: colors.verde_1,
                        color: colors.blanco
                    }}
                >
                    <Label
                        style={{
                            fontSize: "24px",
                            fontFamily:"monospace"
                        }}
                    >
                        COMO LLEGAR
                    </Label>

                </ModalHeader>
                <ModalBody>

                    <Row>
                        <Col>
                            <center>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3820.753553627079!2d-93.10200788513261!3d16.739144088470145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDQ0JzIwLjkiTiA5M8KwMDUnNTkuNCJX!5e0!3m2!1ses!2smx!4v1680257645289!5m2!1ses!2smx"
                                    width={800}
                                    height={600}
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title='Mapa'
                                />
                            </center>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            backgroundColor: colors.azul,
                            boxShadow: "none",
                            borderColor: colors.azul,
                            color: colors.blanco,
                            fontWeight: "bold"
                        }}
                        onClick={() => { openModalMap(false) }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default NavBarComputer;