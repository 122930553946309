/** Importación de componentes */
import SNavBar from "../components/SNavBar"
import { Col, Label, Row, Button, Card, CardTitle, CardText, FormGroup, Input, ModalHeader, Modal, ModalBody, FormText, DropdownItem } from "reactstrap";
import Block from "../components/Block";
import colors from "../config/colors.config";
import { useEffect, useState } from "react";
import Iframe from 'react-iframe'
import { ToastContainer, toast } from 'react-toastify';
import Screen from "../config/screen.config";
import { Close, Email, Phone, QueryBuilder, Room } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

/** Importación de recursos */
import img01 from "../assets/image/background/001.jpg"
// import img04 from "../assets/image/background/007.jpg"
import img03 from "../assets/image/background/008.jpg"
import img07 from "../assets/image/background/007.jpg"
import fondoSection from "../assets/image/background/fondoSection.jpg"
import fondoSection01 from "../assets/image/background/fondoSection01.jpg"
import fondoSection02 from "../assets/image/background/fonsoSection02.jpg"
// import fondoSection01 from "../assets/image/background/1.png"
// import fondoSection from "../assets/image/background/2.jpg"
// import fondoSection02 from "../assets/image/background/3.jpg"
import logo from "../assets/image/logo/logo_2.png";
import logo2 from "../assets/image/logo/logoTzedaka.jpg";
import sitWoman_1 from "../assets/image/mujerCentada.webp";
import manSitting_1 from "../assets/image/hombreContento.png";
import sitWoman_2 from "../assets/image/mujerCentada2.png";
import manSitting_2 from "../assets/image/hombreContento2.png";
import components from "../tools/components.tool";
import ClientServices from "../services/Client.service";
import Device from "../config/device.config";
// import principios from "../assets/image/imgPrincipio-removebg-preview.png";
// import significado from "../assets/image/imgSignificado-removebg-preview.png";



const SIndex = () => {

    const [isHoveredButton, setIsHoveredButton] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [heightIframe, setHeightIframe] = useState(null);
    const [isHoveredLogo, setIsHoveredLogo] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isImageHidden, setIsImageHidden] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [device, setDevice] = useState(Device.getData());
    const [formData, setFormData] = useState({
        asunto: "",
        nombreCompleto: "",
        telefono: "",
        correo: "",
        mensaje: "",
    });

    const images = [
        sitWoman_1,
        manSitting_1,
        sitWoman_2,
        manSitting_2
    ];


    useEffect(() => {

        const imageTimeout = setTimeout(() => {
            setIsImageHidden(true); // Oculta la imagen actual
        }, 9500); // Espera 4.5 segundos antes de ocultar la imagen

        const changeImageTimeout = setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            setIsImageHidden(false); // Muestra la nueva imagen
        }, 10000); // Cambia la imagen después de 5 segundos (5000 milisegundos)

        return () => {
            clearTimeout(imageTimeout); // Limpia el temporizador de ocultar imagen
            clearTimeout(changeImageTimeout); // Limpia el temporizador de cambiar imagen
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setHeightIframe(Screen.getHeightScreen(75));
        window.addEventListener("resize", () => {
            setHeightIframe(Screen.getHeightScreen(75));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },);

    const handlerChangeForm = async ({ target }) => {
        let { name, value } = target;
        await setFormData({
            ...formData,
            [name]: value,
        });
    }

    const validateDataForm = () => {
        let value = true;
        if (!isValidEmail) {
            value = false;
            components.focusElement("correo");
        } else {
            if (formData.asunto.length === 0) {
                value = false;
                components.focusElement("asunto");
            } else {
                if (formData.nombreCompleto.length === 0) {
                    value = false;
                    components.focusElement("nombreCompleto");
                } else {
                    if (formData.telefono.length !== 10) {
                        value = false;
                        components.focusElement("telefono");
                    } else {
                        if (formData.mensaje.length === 0) {
                            value = false;
                            components.focusElement("mensaje");
                        }
                    }
                }
            }
        }
        console.log(formData)
        return value;
    }

    const selectWidthImagen = () => {
        let width = 0
        if (currentImageIndex === 0) {
            width = "80%"
        }
        if (currentImageIndex === 1) {
            width = "100%"
        }
        if (currentImageIndex === 2) {
            width = "70%"
        }
        if (currentImageIndex === 3) {
            width = "60%"
        }
        return width
    }

    return (
        <>
            <ToastContainer
                position={device.isDesktop ? "bottom-right" : "top-center"}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <SNavBar />

            {/* Inicio */}
            {
                device.isDesktop ?
                    <section
                        id="init"
                        style={{
                            width: "100%",
                            height: "100vh",
                            background: `url(${img01}) no-repeat center center/cover fixed`,
                            // fontFamily: "monospace"
                        }}
                    >
                        <Block height={"35vh"} />
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,
                                borderRadius: 10,
                                backdropFilter: "blur(6px)",
                                backgroundColor: "rgb(255,255,255,0.3)"
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "75%",
                                    borderRadius: 10,
                                }}
                            >
                                <Row
                                    // noGutters
                                    style={{
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        marginTop: 20,
                                    }}
                                >


                                    <Col
                                        style={{
                                            width: "90%"
                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontSize: 72,
                                                color: colors.azul,
                                                fontWeight: "bold",
                                                // textShadow: `-1px -1px 0 ${colors.blanco}, 1px -1px 0 ${colors.blanco}, -1px 1px 0 ${colors.blanco}, 1px 1px 0 ${colors.blanco}`,
                                                marginTop: 25,
                                                marginLeft: 7
                                            }}
                                        >
                                            Somos Tzedaká
                                        </Label>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: "0px",
                                        marginRight: "0px"
                                    }}
                                >
                                    <p style={{
                                        marginTop: 30,
                                        maxWidth: "100%",
                                        textAlign: "justify",
                                        fontSize: 52,
                                        color: colors.azul,
                                        // fontWeight: "bold",
                                        marginBottom: 10,
                                        //textShadow: `-1px -1px 0 ${colors.blanco}, 1px -1px 0 ${colors.blanco}, -1px 1px 0 ${colors.blanco}, 1px 1px 0 ${colors.blanco}`,
                                    }}
                                    >
                                        CRÉDITOS JUSTOS AL ALCANCE DE TODOS
                                    </p>
                                </Row>
                                <Row
                                    style={{
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        marginTop: 30,
                                    }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: colors.azul,
                                            borderColor: colors.azul,
                                            color: colors.blanco,
                                            maxWidth: 300,
                                            marginLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 24,
                                            boxShadow: "none",
                                            transform: isHoveredButton ? "scale(1.1)" : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            marginBottom: 20
                                        }}
                                        onMouseEnter={() => { setIsHoveredButton(true); }}
                                        onMouseLeave={() => { setIsHoveredButton(false); }}
                                        onClick={() => {
                                            ///window.location.href = "#products";
                                            const targetElement = document.querySelector("#products");
                                            if (targetElement) {
                                                targetElement.scrollIntoView({
                                                    behavior: "smooth", // Hace que el desplazamiento sea suave
                                                    block: "start", // Opcional: puedes ajustar la alineación del elemento (start, center, end)
                                                    inline: "start", // Opcional: ajusta la alineación horizontal (start, center, end)
                                                    duration: 2000, // Duración de la animación en milisegundos (1 segundo en este ejemplo)
                                                });
                                            }
                                        }}
                                    >
                                        Nuestros productos
                                    </Button>
                                </Row>
                            </Col>
                            {/* <Col
                                style={{
                                    maxWidth: "25%",
                                }}
                            >                             
                                <img
                                    onMouseEnter={() => { setIsHoveredLogo(true); }}
                                    onMouseLeave={() => { setIsHoveredLogo(false); }}
                                    src={logo}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        width: "60%",
                                        marginTop: -20,
                                        transform: isHoveredLogo ? "scale(1.5)" : "scale(1)",
                                        transition: "transform 0.3s ease",
                                        cursor: "pointer",
                                    }}
                                />                                
                            </Col> */}
                        </Row>

                    </section>
                    :
                    <section
                        id="init"
                        style={{
                            width: "100%",
                            height: "100vh",
                            background: `url(${img01}) no-repeat center center/cover fixed`,
                        }}
                    >
                        <Block height={"10vh"} />
                        <Row style={{ marginRight: 0, marginLeft: 0 }} >
                            <Col style={{ textAlign: "center" }} >
                                <img
                                    src={logo}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        width: "50%",
                                        transform: isHoveredLogo ? "scale(1.5)" : "scale(1)",
                                        transition: "transform 0.3s ease",
                                        cursor: "pointer",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                            <Col style={{ textAlign: "left" }}  >
                                <Label
                                    style={{
                                        fontSize: 50,
                                        color: colors.azul,
                                        fontWeight: "bold",
                                        textShadow: `-1px -1px 0 ${colors.blanco}, 1px -1px 0 ${colors.blanco}, -1px 1px 0 ${colors.blanco}, 1px 1px 0 ${colors.blanco}`,
                                        marginTop: 25,
                                        marginLeft: 7,
                                        //lineHeight: 1,
                                    }}
                                >
                                    Somos Tzedaká
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                            <Col style={{ textAlign: "left" }}  >
                                <Row
                                    style={{
                                        marginLeft: "0px",
                                        marginRight: "0px"
                                    }}
                                >
                                    <p style={{
                                        maxWidth: "100%",
                                        textAlign: "justify",
                                        fontSize: 40,
                                        color: colors.azul,
                                        fontWeight: "bold",
                                        lineHeight: 1,
                                        textShadow: `-1px -1px 0 ${colors.blanco}, 1px -1px 0 ${colors.blanco}, -1px 1px 0 ${colors.blanco}, 1px 1px 0 ${colors.blanco}`,
                                    }}
                                    >
                                        CRÉDITOS JUSTOS AL ALCANCE DE TODOS
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                            <Col>
                                <Button
                                    style={{
                                        backgroundColor: colors.azul,
                                        borderColor: colors.azul,
                                        color: colors.blanco,
                                        maxWidth: 300,
                                        marginLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                        boxShadow: "none",
                                        transition: "transform 0.3s ease",
                                    }}
                                    onClick={() => {
                                        const targetElement = document.querySelector("#products");
                                        if (targetElement) {
                                            targetElement.scrollIntoView({
                                                behavior: "smooth", // Hace que el desplazamiento sea suave
                                                block: "start", // Opcional: puedes ajustar la alineación del elemento (start, center, end)
                                                inline: "start", // Opcional: ajusta la alineación horizontal (start, center, end)
                                                duration: 2000, // Duración de la animación en milisegundos (1 segundo en este ejemplo)
                                            });
                                        }
                                    }}
                                >
                                    Nuestros productos
                                </Button>
                            </Col>
                        </Row>
                    </section>
            }


            {/* Productos */}
            {
                device.isDesktop ?
                    <section
                        id="products"
                        style={{
                            width: "100%",
                            height: "100vh",
                            background: `url(${fondoSection}) no-repeat center center/cover fixed`,
                        }}
                    >
                        <Block height={"10vh"} />
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,

                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 42,
                                    textShadow: `-1px -1px 0 ${colors.azul}, 1px -1px 0 ${colors.azul}, -1px 1px 0 ${colors.azul}, 1px 1px 0 ${colors.azul}`,
                                    backdropFilter: "blur(4px)",
                                    width: 550,
                                    padding: 10
                                }}
                            >
                                Te ofrecemos créditos de
                            </Label>
                        </Row>
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,
                                marginTop: 10
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "50%"
                                }}
                            >
                                <Row>
                                    <Col>
                                        <Card
                                            body
                                            className="text-center"
                                            style={{
                                                width: '18rem',
                                                height: 230,
                                                // backgroundColor:"rgb(255,255,255,0.1)",
                                                // backdropFilter: "blur(4px)",
                                            }}
                                        >
                                            <CardTitle
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    fontSize: 28
                                                }}
                                            >
                                                $6,000.00
                                            </CardTitle>
                                            <CardText
                                                style={{
                                                    color: colors.azul,
                                                    fontSize: 18,
                                                    // textAlign: "justify"
                                                }}
                                            >
                                                Recuerda que los grandes logros a menudo requieren de un pequeño impulso inicial.
                                            </CardText>
                                            <Button
                                                style={{
                                                    boxShadow: "none",
                                                    marginTop: 10,
                                                    backgroundColor: colors.azul,
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/#contact";
                                                    components.sleep(1).then(() => {
                                                        components.focusElement("asunto")
                                                    });
                                                }}
                                            >
                                                Solicitar Crédito
                                            </Button>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card
                                            body
                                            className="text-center"
                                            style={{
                                                width: '18rem',
                                                height: 230
                                            }}
                                        >
                                            <CardTitle
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    fontSize: 28
                                                }}
                                            >
                                                $15,000.00
                                            </CardTitle>
                                            <CardText
                                                style={{
                                                    color: colors.azul,
                                                    fontSize: 18,
                                                    // textAlign: "justify"
                                                }}
                                            >
                                                No subestimes tu capacidad para manejar las responsabilidades financieras.
                                            </CardText>
                                            <Button
                                                style={{
                                                    boxShadow: "none",
                                                    marginTop: 10,
                                                    backgroundColor: colors.azul,
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/#contact";
                                                    components.sleep(1).then(() => {
                                                        components.focusElement("asunto")
                                                    });
                                                }}
                                            >
                                                Solicitar Crédito
                                            </Button>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: 50
                                    }}
                                >
                                    <Col>
                                        <Card
                                            body
                                            className="text-center"
                                            style={{
                                                width: '18rem',
                                                height: 230
                                            }}
                                        >
                                            <CardTitle
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    fontSize: 28
                                                }}
                                            >
                                                $20,000.00
                                            </CardTitle>
                                            <CardText
                                                style={{
                                                    color: colors.azul,
                                                    fontSize: 18,
                                                    // textAlign: "justify"
                                                }}
                                            >
                                                El crédito puede ser una herramienta poderosa para alcanzar tus metas financieras y realizar tus sueños
                                            </CardText>
                                            <Button
                                                style={{
                                                    boxShadow: "none",
                                                    marginTop: 10,
                                                    backgroundColor: colors.azul,
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/#contact";
                                                    components.sleep(1).then(() => {
                                                        components.focusElement("asunto")
                                                    });
                                                }}
                                            >
                                                Solicitar Crédito
                                            </Button>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card
                                            body
                                            className="text-center"
                                            style={{
                                                width: '18rem',
                                                height: 230
                                            }}
                                        >
                                            <CardTitle
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    fontSize: 28
                                                }}
                                            >
                                                $30,000.00
                                            </CardTitle>
                                            <CardText
                                                style={{
                                                    color: colors.azul,
                                                    fontSize: 18,
                                                    // textAlign: "justify"
                                                }}
                                            >
                                                Un crédito grande puede abrir puertas a oportunidades aún mayores. No temas dar el salto y convertir tus sueños en realidad.
                                            </CardText>
                                            <Button
                                                style={{
                                                    boxShadow: "none",
                                                    marginTop: 10,
                                                    backgroundColor: colors.azul,
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/#contact";
                                                    components.sleep(1).then(() => {
                                                        components.focusElement("asunto")
                                                    });
                                                }}
                                            >
                                                Solicitar Crédito
                                            </Button>
                                        </Card>
                                    </Col>
                                </Row>

                            </Col>
                            <Col
                                style={{
                                    maxWidth: "50%"
                                }}
                            >
                                <center>
                                    <img
                                        src={images[currentImageIndex]}
                                        alt="No se pudo cargar la imagen"
                                        width={selectWidthImagen()}
                                        // width={"90%"}  
                                        className={`fade-in-out ${isImageHidden ? 'hidden' : ''}`}
                                    // onLoad={() => {
                                    //     Time.sleep(5).then(() => {
                                    //         setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);                                        
                                    //     });
                                    // }}

                                    />

                                </center>
                            </Col>

                        </Row>
                    </section>
                    :
                    <section
                        id="products"
                        style={{
                            width: "100%",
                            height: "auto",
                            backgroundColor: colors.azul
                        }}
                    >
                        <Block height={"5vh"} />
                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 28,
                                    textShadow: `-1px -1px 0 ${colors.azul}, 1px -1px 0 ${colors.azul}, -1px 1px 0 ${colors.azul}, 1px 1px 0 ${colors.azul}`,
                                    backdropFilter: "blur(4px)",
                                    width: 550,
                                    padding: 10
                                }}
                            >
                                Te ofrecemos créditos de
                            </Label>
                        </Row>

                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <Col style={{ textAlign: "center" }} >
                                <Card
                                    body
                                    style={{
                                        width: '100%',
                                        height: 230,
                                    }}
                                >
                                    <CardTitle
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: 28,
                                            textAlign: "left"
                                        }}
                                    >
                                        $6,000.00
                                    </CardTitle>
                                    <CardText
                                        style={{
                                            color: colors.azul,
                                            fontSize: 18,
                                            textAlign: "justify"
                                        }}
                                    >
                                        Recuerda que los grandes logros a menudo requieren de un pequeño impulso inicial.
                                    </CardText>
                                    <Button
                                        style={{
                                            boxShadow: "none",
                                            marginTop: 10,
                                            backgroundColor: colors.azul,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#contact";
                                            components.sleep(1).then(() => {
                                                components.focusElement("asunto")
                                            });
                                        }}
                                    >
                                        Solicitar Crédito
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20 }}>
                            <Col style={{ textAlign: "center" }} >
                                <Card
                                    body
                                    style={{
                                        width: '100%',
                                        height: 230
                                    }}
                                >
                                    <CardTitle
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: 28,
                                            textAlign: "left"
                                        }}
                                    >
                                        $15,000.00
                                    </CardTitle>
                                    <CardText
                                        style={{
                                            color: colors.azul,
                                            fontSize: 18,
                                            textAlign: "justify"
                                        }}
                                    >
                                        No subestimes tu capacidad para manejar las responsabilidades financieras.
                                    </CardText>
                                    <Button
                                        style={{
                                            boxShadow: "none",
                                            marginTop: 10,
                                            backgroundColor: colors.azul,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#contact";
                                            components.sleep(1).then(() => {
                                                components.focusElement("asunto")
                                            });
                                        }}
                                    >
                                        Solicitar Crédito
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20 }}>
                            <Col style={{ textAlign: "center" }} >
                                <Card
                                    body
                                    className="text-center"
                                    style={{
                                        width: '100%',
                                        height: 230
                                    }}
                                >
                                    <CardTitle
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: 28,
                                            textAlign: "left"
                                        }}
                                    >
                                        $20,000.00
                                    </CardTitle>
                                    <CardText
                                        style={{
                                            color: colors.azul,
                                            fontSize: 18,
                                            textAlign: "justify"
                                        }}
                                    >
                                        El crédito puede ser una herramienta poderosa para alcanzar tus metas financieras y realizar tus sueños
                                    </CardText>
                                    <Button
                                        style={{
                                            boxShadow: "none",
                                            marginTop: 10,
                                            backgroundColor: colors.azul,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#contact";
                                            components.sleep(1).then(() => {
                                                components.focusElement("asunto")
                                            });
                                        }}
                                    >
                                        Solicitar Crédito
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20, }}>
                            <Col style={{ textAlign: "center" }} >
                                <Card
                                    body
                                    style={{
                                        width: '100%',
                                        height: 230
                                    }}
                                >
                                    <CardTitle
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: 28,
                                            textAlign: "left"
                                        }}
                                    >
                                        $30,000.00
                                    </CardTitle>
                                    <CardText
                                        style={{
                                            color: colors.azul,
                                            fontSize: 18,
                                            textAlign: "justify"
                                        }}
                                    >
                                        Un crédito grande puede abrir puertas a oportunidades aún mayores. No temas dar el salto y convertir tus sueños en realidad.
                                    </CardText>
                                    <Button
                                        style={{
                                            boxShadow: "none",
                                            marginTop: 10,
                                            backgroundColor: colors.azul,
                                        }}
                                        onClick={() => {
                                            window.location.href = "/#contact";
                                            components.sleep(1).then(() => {
                                                components.focusElement("asunto")
                                            });
                                        }}
                                    >
                                        Solicitar Crédito
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        <Block height={20} />
                    </section>
            }


            {/* Misión y Vision */}
            {
                device.isDesktop ?
                    <section
                        id="mision"
                        style={{
                            width: "100%",
                            height: "100vh",
                            background: `url(${img03}) no-repeat center center/cover fixed`,
                            textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                        }}
                    >
                        <Block height={"10vh"} />
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,

                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 42,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    backdropFilter: "blur(4px)",
                                    // width: 1600,
                                    padding: 10,
                                    lineHeight: 1
                                }}
                            >
                                Nuestra Misión y Vision
                            </p>
                        </Row>

                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,
                                backdropFilter: "blur(4px)",
                                backgroundColor: "rgb(255,255,255,0.25)",
                                borderRadius: "10px 10px 0px 0px",
                                color: colors.blanco,
                                padding: 10
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    width: "50%"
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 36,
                                        lineHeight: 1.3,
                                    }}
                                >
                                    Nuestra Misión
                                </Label>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1,
                                        marginTop: 5,
                                        textAlign: "justify"
                                    }}
                                >
                                    En Tzedaká, nos enorgullece cumplir con nuestra misión de proporcionar créditos a familias,
                                    permitiéndoles alcanzar sus metas financieras, construir un futuro sólido y asegurar la
                                    estabilidad de sus seres queridos. Nuestro compromiso inquebrantable con el bienestar
                                    económico de las familias es lo que impulsa cada una de nuestras acciones.
                                </p>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    width: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets6.lottiefiles.com/packages/lf20_j7dhg1hl.json"
                                    speed={0.6}
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        marginTop: "30px"
                                    }}
                                />
                            </Col>
                            <DropdownItem divider />
                        </Row>
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,
                                backdropFilter: "blur(4px)",
                                backgroundColor: "rgb(255,255,255,0.25)",
                                borderRadius: "0px 0px 10px 10px",
                                color: colors.blanco,
                                padding: 10
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    width: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <lottie-player
                                    autoplay
                                    loop
                                    mode="normal"
                                    src="https://assets6.lottiefiles.com/packages/lf20_22mjkcbb.json"
                                    speed={0.7}
                                    style={{
                                        width: "290px",
                                        height: "290px",
                                        marginTop: "20px"
                                    }}
                                />
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    width: "50%"
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 36,
                                        lineHeight: 1.3,
                                    }}
                                >
                                    Nuestra Vision
                                </Label>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1,
                                        marginTop: 5,
                                        textAlign: "justify"
                                    }}
                                >
                                    En Tzedaká, nuestra misión es brindar créditos con intereses justos al alcance de todos,
                                    guiados por principios sólidos de respeto, confianza y honestidad que son la base de cada
                                    una de nuestras operaciones. Nuestro compromiso con la justicia, la solidaridad y la bondad
                                    se refleja en la forma en que aplicamos estos valores en todas nuestras acciones y decisiones,
                                    asegurando que cada individuo y familia tenga la oportunidad de lograr su bienestar financiero
                                    a través de nuestros servicios.
                                </p>
                            </Col>
                        </Row>

                    </section>
                    :
                    <section
                        id="mision"
                        style={{
                            width: "100%",
                            height: "auto",
                            background: `url(${img03}) no-repeat center center/cover fixed`,
                            textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                        }}
                    >
                        <Block height={"5vh"} />
                        <Row style={{ marginRight: 5, marginLeft: 5, }} >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 30,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    // backdropFilter: "blur(4px)",
                                    // width: 1600,
                                    padding: 10,
                                    lineHeight: 1
                                }}
                            >
                                Nuestra Misión y Vision
                            </p>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, backdropFilter: "blur(4px)" }} >
                            <Col >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        lineHeight: 1.3,
                                        color: colors.blanco,
                                    }}
                                >
                                    Nuestra Misión
                                </Label>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        lineHeight: 1,
                                        marginTop: 5,
                                        textAlign: "justify",
                                        color: colors.blanco,
                                    }}
                                >
                                    En Tzedaká, nos enorgullece cumplir con nuestra misión de proporcionar créditos a familias,
                                    permitiéndoles alcanzar sus metas financieras, construir un futuro sólido y asegurar la
                                    estabilidad de sus seres queridos. Nuestro compromiso inquebrantable con el bienestar
                                    económico de las familias es lo que impulsa cada una de nuestras acciones.
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, backdropFilter: "blur(4px)" }} >
                            <Col  >
                                <center>
                                    <lottie-player
                                        autoplay
                                        loop
                                        mode="normal"
                                        src="https://assets6.lottiefiles.com/packages/lf20_j7dhg1hl.json"
                                        speed={0.6}
                                        style={{
                                            width: "80%",
                                            // height: "200px",
                                            marginTop: "30px"
                                        }}
                                    />
                                </center>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20, color: colors.blanco, backdropFilter: "blur(4px)" }} >
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 25,
                                        lineHeight: 1.3,
                                    }}
                                >
                                    Nuestra Visions
                                </Label>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        lineHeight: 1,
                                        marginTop: 5,
                                        textAlign: "justify"
                                    }}
                                >
                                    En Tzedaká, nuestra misión es brindar créditos con intereses justos al alcance de todos,
                                    guiados por principios sólidos de respeto, confianza y honestidad que son la base de cada
                                    una de nuestras operaciones. Nuestro compromiso con la justicia, la solidaridad y la bondad
                                    se refleja en la forma en que aplicamos estos valores en todas nuestras acciones y decisiones,
                                    asegurando que cada individuo y familia tenga la oportunidad de lograr su bienestar financiero
                                    a través de nuestros servicios.
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, backdropFilter: "blur(4px)" }} >
                            <Col  >
                                <center>
                                    <lottie-player
                                        autoplay
                                        loop
                                        mode="normal"
                                        src="https://assets6.lottiefiles.com/packages/lf20_22mjkcbb.json"
                                        speed={0.7}
                                        style={{
                                            width: "290px",
                                            height: "290px",
                                            marginTop: "20px"
                                        }}
                                    />
                                </center>
                            </Col>
                        </Row>
                        <Block height={40} />
                    </section>
            }


            {/* Quienes somos */}
            {
                device.isDesktop ?
                    <section
                        id="who-is"
                        style={{
                            width: "100%",
                            height: "100vh",
                            background: `url(${fondoSection01}) no-repeat center center/cover fixed`,
                            // fontFamily: "monospace"
                        }}
                    >
                        <Block height={"10vh"} />
                        <Row style={{ marginRight: 50, marginLeft: 50, }}>
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 42,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    // backdropFilter: "blur(4px)",
                                    // width: 1600,
                                    padding: 10,
                                    lineHeight: 1
                                }}
                            >
                                Somos Una Sociedad Financiera
                            </p>
                        </Row>
                        <Row
                            style={{
                                marginRight: 50,
                                marginLeft: 50,
                                marginTop: 20,
                                padding: 10,
                                // backdropFilter: "blur(4px)",
                                // backgroundColor: "rgb(255,255,255,0.25)",
                                borderRadius: 10
                            }}
                        >
                            <Col
                                style={{
                                    maxWidth: "50%",
                                    color: colors.blanco,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={{
                                        padding: 10,
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1.3,
                                        marginTop: 30
                                    }}
                                >
                                    En Tzedaká, somos sociedad financiera se fundamenta en principios sólidos de respeto, confianza y honestidad,
                                    los cuales guían cada una de nuestras operaciones. Además, comprometidos con la justicia, solidaridad y bondad,
                                    aplicamos estos valores en todas nuestras acciones y decisiones
                                </p>
                                <p
                                    style={{
                                        padding: 10,
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1.3
                                    }}>
                                    Tzedaká es una palabra hebrea que significa solidaridad. Tiene su raíz en la palabra tzedek, justicia. Así,
                                    <strong style={{ color: colors.verde_1 }}> Tzedaká significa Justicia y Solidaridad.</strong> Es la restitución
                                    del derecho de un semejante a vivir con dignidad.
                                </p>
                            </Col>
                            <Col
                                style={{
                                    maxWidth: "50%"
                                }}
                            >
                                <center>
                                    <img
                                        src={logo2}
                                        alt="No se pudo cargar la imagen"
                                        style={{
                                            width: "80%",
                                            borderRadius: 10,
                                            marginTop: 30,
                                            marginBottom: 30,
                                            transform: isHoveredButton ? "scale(1.1)" : "scale(1)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                            borderColor: colors.azul

                                        }}
                                        onMouseEnter={() => { setIsHoveredButton(true); }}
                                        onMouseLeave={() => { setIsHoveredButton(false); }}
                                    />
                                </center>
                            </Col>
                        </Row>
                    </section>
                    :
                    <section
                        id="who-is"
                        style={{
                            width: "100%",
                            height: "auto",
                            backgroundColor: colors.azul,
                            // fontFamily: "monospace"
                        }}
                    >
                        <Block height={"5vh"} />
                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <p
                                style={{
                                    fontWeight: "bold",
                                    color: colors.blanco,
                                    fontSize: 30,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    // backdropFilter: "blur(4px)",
                                    // width: 1600,
                                    padding: 10,
                                    lineHeight: 1
                                }}
                            >
                                Somos Una Sociedad Financiera
                            </p>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <Col
                                style={{
                                    color: colors.blanco,
                                    textShadow: `-1px -1px 0 ${colors.negro}, 1px -1px 0 ${colors.negro}, -1px 1px 0 ${colors.negro}, 1px 1px 0 ${colors.negro}`,
                                    textAlign: "justify"
                                }}
                            >
                                <p
                                    style={{
                                        padding: 10,
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1.3,
                                        marginTop: 30
                                    }}
                                >
                                    En Tzedaká, somos sociedad financiera se fundamenta en principios sólidos de respeto, confianza y honestidad,
                                    los cuales guían cada una de nuestras operaciones. Además, comprometidos con la justicia, solidaridad y bondad,
                                    aplicamos estos valores en todas nuestras acciones y decisiones
                                </p>
                                <p
                                    style={{
                                        padding: 10,
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        lineHeight: 1.3
                                    }}>
                                    Tzedaká es una palabra hebrea que significa solidaridad. Tiene su raíz en la palabra tzedek, justicia. Así,
                                    <strong style={{ color: colors.verde_1 }}> Tzedaká significa Justicia y Solidaridad.</strong> Es la restitución
                                    del derecho de un semejante a vivir con dignidad.
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, }}>
                            <Col >
                                <center>
                                    <img
                                        src={logo2}
                                        alt="No se pudo cargar la imagen"
                                        style={{
                                            width: "100%",
                                            borderRadius: 10,
                                            marginTop: 30,
                                            marginBottom: 30,
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                            borderColor: colors.azul

                                        }}
                                    />
                                </center>
                            </Col>
                        </Row>
                    </section>
            }


            {/* Sucursales */}
            <section
                id="sucursales"
                style={{
                    width: "100%",
                    height: "100vh",
                    background: `url(${img07}) no-repeat center center/cover fixed`,
                    // fontFamily: "monospace"
                }}
            >
                <Row style={{ marginRight: 5, marginLeft: 5, }}>
                    <Col
                        style={{
                            // maxWidth: "50%",
                            // height: "100vh",
                        }}
                    >
                        <center>
                            <Block height={device.isDesktop ? "10vh" : "5vh"} />
                            <Row
                                style={{
                                    width: "100%",
                                    marginTop: 10
                                }}
                            >
                                <Label
                                    style={{
                                        textAlign: "left",
                                        fontSize: device.isDesktop ? 35 : 25,
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        textShadow: `-1px -1px 0 ${colors.azul}, 1px -1px 0 ${colors.azul}, -1px 1px 0 ${colors.azul}, 1px 1px 0 ${colors.azul}`,
                                        lineHeight: 1,
                                    }}
                                >
                                    Mapa de nuestra sucursal en Tuxtla Gutierrez Chiapas.
                                </Label>
                            </Row>
                            <Iframe
                                url="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3820.753553627079!2d-93.10200788513261!3d16.739144088470145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDQ0JzIwLjkiTiA5M8KwMDUnNTkuNCJX!5e0!3m2!1ses!2smx!4v1680218289312!5m2!1ses!2smx"
                                width="100%"
                                height={device.isDesktop ? heightIframe : `${heightIframe * 0.8}px`}
                                id=""
                                className=""
                                display="block"
                                position="relative"
                                styles={{ borderRadius: 10, marginTop: 10, }}
                            />
                        </center>
                    </Col>

                </Row>
            </section>

            {/* Contacto */}
            {
                device.isDesktop ?
                    <Row
                        id="contact"
                        style={{
                            width: "100%",
                            height: "100vh",
                            // marginRight: 50,
                            // marginLeft: 50,
                            background: `url(${fondoSection02}) no-repeat center center/cover fixed`,
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "50%",
                                height: "100vh",
                            }}
                        >
                            <Block height={"10vh"} />

                            <center>
                                <div
                                    style={{
                                        border: `2px solid ${colors.azul}`,
                                        // height: heightIframe,
                                        marginRight: "5%",
                                        marginLeft: "5%",
                                        // background: `url(${logo2}) no-repeat center`,
                                        backdropFilter: "blur(4px)",
                                        backgroundColor: colors.blanco,
                                        borderRadius: 10,
                                        color: colors.azul,
                                        fontWeight: "bold"
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                            marginTop: 30
                                        }}
                                    >
                                        <FormGroup>
                                            <Label>
                                                Asunto
                                            </Label>
                                            <Input
                                                style={{
                                                    boxShadow: "none",
                                                    borderColor: colors.azul
                                                }}
                                                id="asunto"
                                                name="asunto"
                                                value={formData.asunto}
                                                placeholder={"..."}
                                                onChange={async (event) => {
                                                    if (event.target.value.length > 50) return;
                                                    await handlerChangeForm(event);
                                                }}
                                            />
                                            {
                                                formData.asunto.length === 0 ?
                                                    null
                                                    :
                                                    <FormText>
                                                        {formData.asunto.length} / 50
                                                    </FormText>
                                            }
                                        </FormGroup>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                        }}
                                    >
                                        <FormGroup>
                                            <Label>
                                                Nombre completo
                                            </Label>
                                            <Input
                                                style={{
                                                    boxShadow: "none",
                                                    borderColor: colors.azul
                                                }}
                                                id="nombreCompleto"
                                                name="nombreCompleto"
                                                placeholder={"Nombre(s) Apellido Paterno y Materno"}
                                                value={formData.nombreCompleto}
                                                onChange={async (event) => {
                                                    if (event.target.value.length > 50) return;
                                                    await handlerChangeForm(event);
                                                }}
                                            />
                                            {
                                                formData.nombreCompleto.length === 0 ?
                                                    null
                                                    :
                                                    <FormText>
                                                        {formData.nombreCompleto.length} / 50
                                                    </FormText>
                                            }
                                        </FormGroup>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                        }}
                                    >
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    Teléfono
                                                </Label>
                                                <Input
                                                    style={{
                                                        boxShadow: "none",
                                                        borderColor: colors.azul
                                                    }}
                                                    id="telefono"
                                                    name="telefono"
                                                    value={formData.telefono}
                                                    placeholder={"9611234567"}
                                                    onChange={async (event) => {
                                                        let { value } = event.target;
                                                        if (value.length === 0) {
                                                            await handlerChangeForm(event);
                                                        } else {
                                                            let response = /^[0-9]+$/.test(value);
                                                            if (response) {
                                                                if (value.length > 10) return
                                                                await handlerChangeForm(event);
                                                            }
                                                        }
                                                    }}
                                                />
                                                {
                                                    formData.telefono.length === 0 ?
                                                        null
                                                        :
                                                        <FormText color={formData.telefono.length === 10 ? "success" : ""} >
                                                            {formData.telefono.length === 10 ? "Numero valido" : "Numero no valido; faltan números"}
                                                        </FormText>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    Correo
                                                </Label>
                                                <Input
                                                    style={{
                                                        boxShadow: "none",
                                                        borderColor: colors.azul
                                                    }}
                                                    placeholder={"micorreo@gmail.com"}
                                                    id="correo"
                                                    name="correo"
                                                    value={formData.correo}
                                                    onChange={async (event) => {
                                                        let { value } = event.target;
                                                        let response = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
                                                        await handlerChangeForm(event);
                                                        await setIsValidEmail(response)

                                                    }}
                                                />
                                                {
                                                    formData.correo.length === 0 ?
                                                        null
                                                        :
                                                        <FormText color={isValidEmail ? "success" : ""} >
                                                            {isValidEmail ? "Correo electrónico valido" : "Correo electrónico no valido"}
                                                        </FormText>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                            // marginTop: 10
                                        }}
                                    >
                                        <FormGroup>
                                            <Label style={{ padding: 5, borderRadius: 10 }} >
                                                Mensaje
                                            </Label>
                                            <Input
                                                id="mensaje"
                                                name="mensaje"
                                                value={formData.mensaje}
                                                placeholder={"..."}
                                                type="textarea"
                                                style={{
                                                    height: 100,
                                                    resize: "none",
                                                    border: `1px solid ${colors.azul}`,
                                                    boxShadow: "none"
                                                }}
                                                onChange={async (event) => {
                                                    let { value } = event.target;
                                                    if (value.length > 300) return;
                                                    await handlerChangeForm(event);
                                                }}
                                            />
                                            {
                                                formData.mensaje.length === 0 ?
                                                    null
                                                    :
                                                    <FormText>
                                                        {formData.mensaje.length} / 300
                                                    </FormText>
                                            }
                                        </FormGroup>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                            marginTop: -10
                                        }}
                                    >
                                        <FormGroup
                                            // check
                                            disabled
                                        >

                                            <Label check>
                                                Acepto el aviso aviso de privacidad
                                            </Label>
                                            {' '}
                                            <Input
                                                id="checkbox"
                                                type="checkbox"
                                                checked={isCheck}
                                                style={{
                                                    marginRight: 10,
                                                    marginLeft: 10,
                                                }}
                                                onClick={() => {
                                                    setIsCheck(!isCheck);
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        setIsCheck(!isCheck);
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "left",
                                            marginTop: -20,
                                            fontWeight: "initial",
                                            fontSize: 14
                                        }}
                                    >
                                        <Label>
                                            ¿Conoces nuestro aviso de privacidad?,
                                            <Label
                                                style={{
                                                    marginLeft: 5,
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                }}
                                                onClick={() => {
                                                    window.open("/aviso-de-privacidad");
                                                }}
                                            >
                                                Haz click aquí para ver nuestro aviso de privacidad
                                            </Label>
                                        </Label>

                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5%",
                                            marginLeft: "5%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    backgroundColor: colors.azul,
                                                    borderColor: colors.azul,
                                                    color: colors.blanco,
                                                    maxWidth: 150,
                                                    width: 150,
                                                    marginRight: 10,
                                                    fontWeight: "bold",
                                                    fontSize: 16,
                                                    boxShadow: "none",
                                                    // marginRight: 0
                                                }}
                                                onClick={() => {
                                                    setFormData({
                                                        asunto: "",
                                                        nombreCompleto: "",
                                                        telefono: "",
                                                        correo: "",
                                                        mensaje: "",
                                                    });
                                                    setIsValidEmail(false);
                                                    components.focusElement("asunto");
                                                }}
                                            >
                                                Limpiar
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.azul,
                                                    borderColor: colors.azul,
                                                    color: colors.blanco,
                                                    maxWidth: 150,
                                                    width: 150,
                                                    //marginLeft: 10,
                                                    fontWeight: "bold",
                                                    fontSize: 16,
                                                    boxShadow: "none",
                                                    // marginRight: 0
                                                }}
                                                onClick={async () => {
                                                    let response = validateDataForm();
                                                    if (response) {
                                                        if (isCheck) {
                                                            let response = await ClientServices.sendFormData(formData)
                                                            if (response.error) {
                                                                toast.error("No se pudo mandar tu formulario");
                                                            } else {
                                                                toast.success("Formulario enviado");
                                                            }
                                                        } else {
                                                            toast.warning("Debes de estar de acuerdo con nuestro aviso de privacidad");
                                                            components.focusElement("checkbox");
                                                        }
                                                    } else {
                                                        toast.warning("Faltan datos por llenar");
                                                    }
                                                }}
                                            >
                                                Enviar
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Block height={10} ></Block>
                                </div>
                            </center>

                        </Col>
                        <Col style={{ fontSize: 24, color: colors.blanco, textShadow: `-1px -1px 0 ${colors.azul}, 1px -1px 0 ${colors.azul}, -1px 1px 0 ${colors.azul}, 1px 1px 0 ${colors.azul}`, }} >
                            <Block height={"20vh"} />
                            <Row
                                style={{
                                    marginRight: "5%",
                                    marginLeft: "5%",
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: "10%"
                                    }}
                                >
                                    <Room style={{ color: colors.blanco, height: 40, width: 40 }} />
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "80%"
                                    }}
                                >
                                    <Row>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Dirección
                                        </Label>
                                        <p>
                                            Calle Vicente Cortes Herrera 465 Col Santa Ana C.P. 29090 Tuxtla Gutiérrez, Chiapas
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5%",
                                    marginLeft: "5%",
                                    marginTop: 40
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: "10%"
                                    }}
                                >
                                    <Email style={{ color: colors.blanco, height: 40, width: 40 }} />
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "80%"
                                    }}
                                >
                                    <Row>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Correo electrónico
                                        </Label>
                                        <p
                                            onClick={() => {
                                                window.location.href = "mailto:operadora@tzedaka.com.mx";
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            title={"Click para mandar un correo"}
                                        >
                                            operadora@tzedaka.com.mx
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5%",
                                    marginLeft: "5%",
                                    marginTop: 40
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: "10%"
                                    }}
                                >
                                    <Phone style={{ color: colors.blanco, height: 40, width: 40 }} />
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "80%"
                                    }}
                                >
                                    <Row>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Teléfono
                                        </Label>
                                        <p
                                            onClick={() => {
                                                window.location.href = `tel:9613465243`;
                                            }}
                                        >
                                            +52 961 346 5243
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5%",
                                    marginLeft: "5%",
                                    marginTop: 40
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: "10%"
                                    }}
                                >
                                    <QueryBuilder style={{ color: colors.blanco, height: 40, width: 40 }} />
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: "80%"
                                    }}
                                >
                                    <Row>
                                        <Label
                                            style={{
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Horario
                                        </Label>
                                        <p>
                                            Lunes - Viernes: 09:00 AM - 06:00 PM
                                        </p>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                    :
                    <section
                        id="contact"
                        style={{
                            width: "100%",
                            height: "100vh",
                            backgroundColor: colors.azul,
                        }}
                    >
                        <Block height={"5vh"} />
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <Label style={{ fontSize: 30, color: colors.blanco, fontWeight: "bold" }} >
                                    Formulario de Contacto
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontWeight: "bold", color: colors.blanco }} >
                                        Asunto
                                    </Label>
                                    <Input
                                        style={{
                                            boxShadow: "none",
                                            borderColor: colors.azul
                                        }}
                                        id="asunto"
                                        name="asunto"
                                        value={formData.asunto}
                                        placeholder={"..."}
                                        onChange={async (event) => {
                                            if (event.target.value.length > 50) return;
                                            await handlerChangeForm(event);
                                        }}
                                    />
                                    {
                                        formData.asunto.length === 0 ?
                                            null
                                            :
                                            <FormText>
                                                {formData.asunto.length} / 50
                                            </FormText>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontWeight: "bold", color: colors.blanco }} >
                                        Nombre completo
                                    </Label>
                                    <Input
                                        style={{
                                            boxShadow: "none",
                                            borderColor: colors.azul
                                        }}
                                        id="nombreCompleto"
                                        name="nombreCompleto"
                                        placeholder={"Nombre(s) Apellido Paterno y Materno"}
                                        value={formData.nombreCompleto}
                                        onChange={async (event) => {
                                            if (event.target.value.length > 50) return;
                                            await handlerChangeForm(event);
                                        }}
                                    />
                                    {
                                        formData.nombreCompleto.length === 0 ?
                                            null
                                            :
                                            <FormText>
                                                {formData.nombreCompleto.length} / 50
                                            </FormText>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontWeight: "bold", color: colors.blanco }} >
                                        Teléfono
                                    </Label>
                                    <Input
                                        style={{
                                            boxShadow: "none",
                                            borderColor: colors.azul
                                        }}
                                        id="telefono"
                                        name="telefono"
                                        value={formData.telefono}
                                        placeholder={"9611234567"}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            if (value.length === 0) {
                                                await handlerChangeForm(event);
                                            } else {
                                                let response = /^[0-9]+$/.test(value);
                                                if (response) {
                                                    if (value.length > 10) return
                                                    await handlerChangeForm(event);
                                                }
                                            }
                                        }}
                                    />
                                    {
                                        formData.telefono.length === 0 ?
                                            null
                                            :
                                            <FormText color={formData.telefono.length === 10 ? "success" : ""} >
                                                {formData.telefono.length === 10 ? "Numero valido" : "Numero no valido; faltan números"}
                                            </FormText>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontWeight: "bold", color: colors.blanco }} >
                                        Correo
                                    </Label>
                                    <Input
                                        style={{
                                            boxShadow: "none",
                                            borderColor: colors.azul
                                        }}
                                        placeholder={"micorreo@gmail.com"}
                                        id="correo"
                                        name="correo"
                                        value={formData.correo}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            let response = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
                                            await handlerChangeForm(event);
                                            await setIsValidEmail(response)

                                        }}
                                    />
                                    {
                                        formData.correo.length === 0 ?
                                            null
                                            :
                                            <FormText color={isValidEmail ? "success" : ""} >
                                                {isValidEmail ? "Correo electrónico valido" : "Correo electrónico no valido"}
                                            </FormText>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5 }} >
                            <Col>
                                <FormGroup>
                                    <Label style={{ fontWeight: "bold", color: colors.blanco }} >
                                        Mensaje
                                    </Label>
                                    <Input
                                        id="mensaje"
                                        name="mensaje"
                                        value={formData.mensaje}
                                        placeholder={"..."}
                                        type="textarea"
                                        style={{
                                            height: 100,
                                            resize: "none",
                                            border: `1px solid ${colors.azul}`,
                                            boxShadow: "none"
                                        }}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            if (value.length > 300) return;
                                            await handlerChangeForm(event);
                                        }}
                                    />
                                    {
                                        formData.mensaje.length === 0 ?
                                            null
                                            :
                                            <FormText>
                                                {formData.mensaje.length} / 300
                                            </FormText>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, color: colors.blanco }} >
                            <FormGroup
                                // check
                                disabled
                            >

                                <Label check>
                                    Acepto el aviso aviso de privacidad
                                </Label>
                                {' '}
                                <Input
                                    id="checkbox"
                                    type="checkbox"
                                    checked={isCheck}
                                    style={{
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                    onClick={() => {
                                        setIsCheck(!isCheck);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            setIsCheck(!isCheck);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Row>
                        <Row
                            style={{
                                marginRight: 5,
                                marginLeft: 5,
                                textAlign: "left",
                                marginTop: -20,
                                fontWeight: "initial",
                                fontSize: 14,
                                color: colors.blanco
                            }}
                        >
                            <Label>
                                ¿Conoces nuestro aviso de privacidad?,
                                <Label
                                    style={{
                                        marginLeft: 5,
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                        window.open("/aviso-de-privacidad");
                                    }}
                                >
                                    Haz click aquí para ver nuestro aviso de privacidad
                                </Label>
                            </Label>

                        </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, color: colors.blanco }}   >
                            <Col>
                                <Button
                                    style={{
                                        backgroundColor: colors.blanco,
                                        borderColor: colors.blanco,
                                        color: colors.azul,
                                        width: "100%",
                                        // width: 150,
                                        marginRight: 10,
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        boxShadow: "none",
                                        // marginRight: 0
                                    }}
                                    onClick={() => {
                                        setFormData({
                                            asunto: "",
                                            nombreCompleto: "",
                                            telefono: "",
                                            correo: "",
                                            mensaje: "",
                                        });
                                        setIsValidEmail(false);
                                        components.focusElement("asunto");
                                    }}
                                >
                                    Limpiar
                                </Button>

                            </Col>
                            {/* </Row>
                        <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20 }}   > */}
                            <Col>
                                <Button
                                    style={{
                                        backgroundColor: colors.blanco,
                                        borderColor: colors.blanco,
                                        color: colors.azul,
                                        width: "100%",
                                        // width: 150,
                                        //marginLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        boxShadow: "none",
                                        // marginRight: 0
                                    }}
                                    onClick={async () => {
                                        let response = validateDataForm();
                                        if (response) {
                                            if (isCheck) {
                                                let response = await ClientServices.sendFormData(formData)
                                                if (response.error) {
                                                    toast.error("No se pudo mandar tu formulario");
                                                } else {
                                                    toast.success("Formulario enviado");
                                                }
                                            } else {
                                                toast.warning("Debes de estar de acuerdo con nuestro aviso de privacidad");
                                                components.focusElement("checkbox");
                                            }
                                        } else {
                                            toast.warning("Faltan datos por llenar");
                                        }
                                    }}
                                >
                                    Enviar
                                </Button>
                            </Col>
                        </Row>
                        <Block height={10} ></Block>
                    </section>
            }

            <Modal
                isOpen={isOpenModal}
                size="lg"
                style={{
                    maxWidth: "60%"
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => {
                                setIsOpenModal(false);
                            }}
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul,
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul,
                        color: colors.blanco,
                    }}
                >
                    <Label
                        style={{
                            fontSize: 24,

                        }}
                    >
                        Comunícate con nosotros a través de nuestros medios de contacto.
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%"
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "10%"
                            }}
                        >
                            <Room style={{ color: colors.azul, height: 40, width: 40 }} />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Row>
                                <Label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Dirección
                                </Label>
                                <p>
                                    Calle Vicente Cortes Herrera 465 Col Santa Ana C.P. 29090 Tuxtla Gutiérrez, Chiapas
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            marginTop: 20
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "10%"
                            }}
                        >
                            <Email style={{ color: colors.azul, height: 40, width: 40 }} />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Row>
                                <Label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Correo electrónico
                                </Label>
                                <p
                                    onClick={() => {
                                        window.location.href = "mailto:operadora@tzedaka.com.mx"
                                    }}
                                >
                                    operadora@tzedaka.com.mx
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            marginTop: 20
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "10%"
                            }}
                        >
                            <Phone style={{ color: colors.azul, height: 40, width: 40 }} />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Row>
                                <Label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Teléfono
                                </Label>
                                <p >
                                    +52 961 346 5243
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            marginTop: 20
                        }}
                    >
                        <Col
                            style={{
                                maxWidth: "10%"
                            }}
                        >
                            <QueryBuilder style={{ color: colors.azul, height: 40, width: 40 }} />
                        </Col>
                        <Col
                            style={{
                                maxWidth: "80%"
                            }}
                        >
                            <Row>
                                <Label
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    Horario
                                </Label>
                                <p>
                                    Lunes - Viernes: 09:00 AM - 06:00 PM
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

        </>
    );

}

export default SIndex;