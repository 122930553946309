import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../config/colors.config.js";
import {
    Row,
    Col,
    Label,
    List,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import {
    Phone,
    Mail,
    //Map,
    Room,
} from "@material-ui/icons"
import img from "../assets/image/background/001.jpg"

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalMap: false,
            orientation: 0,
        }

    }
    componentDidMount() {
        this.props.isDesktopDevice ?
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`

        this.setState({
            orientation: window.orientation
        });
        window.addEventListener("orientationchange", () => {
            // Hacer algo cuando cambia la orientación
            this.setState({
                orientation: window.orientation
            });
        }, false);

    }

    render() {
        return (
            <>
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />



                <div
                    style={{
                        marginTop: "100px",
                        marginRight: this.props.isDesktopDevice ? "10%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "10%" : "5px",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        borderRadius: "8px",
                        
                    }}
                >
                    <Row>
                        <Col>
                        <center>
                            <Label
                                style={{
                                    fontWeight:"bold",
                                    fontSize:"48px",
                                    color:colors.verde_1,
                                    marginTop:"30px"
                                }}
                            >
                            TZEDAKÁ
                            </Label>
                        </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "50px",                            
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: this.props.isDesktopDevice ? "25px" : "25px",
                                        color: colors.verde_1,
                                        // color:"#FBB23C",
                                        fontWeight: "bold",

                                    }}
                                >
                                    ¡ESTAMOS CONSTRUYENDO ALGO FANTÁSTICO!

                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "50px",                            
                        }}
                    >
                        <Col>
                        </Col>
                    </Row>
                    {/* <Row
                        style={{
                            marginTop: "30px"
                        }}
                    >
                        <Col>
                            <center>
                                <img src={imgPrincipioremovebgpreview} alt="No imagen" height={225}/>
                            </center>
                        </Col>

                        <Col>

                            <center>
                                <img src={imgSignificadoremovebgpreview} alt="No imagen" height={225} />
                            </center>
                        </Col>
                    </Row> */}

                </div>

                <div
                    style={{
                        marginTop: "10px",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        marginRight: this.props.isDesktopDevice ? "10%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "10%" : "5px",
                        borderRadius: "8px"
                    }}
                >
                    {
                        this.props.isDesktopDevice ?
                            <Row

                            >
                                <Col

                                    style={{
                                        backgroundColor: "",
                                    }}
                                >

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d955.1889164052288!2d-93.10034417080576!3d16.73903878355697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd878fbd72523%3A0x338b299c76ef2782!2sC.%20Vicente%20Cort%C3%A9s%20Herrera%20465%2C%20Caminera%2C%2029090%20Tuxtla%20Guti%C3%A9rrez%2C%20Chis.!5e0!3m2!1ses!2smx!4v1680144524766!5m2!1ses!2smx"
                                        width="100%"
                                        height={400}
                                        style={{
                                            border: 0
                                        }}
                                        allowFullScreen
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Mapa"
                                    />

                                </Col>
                                <Col

                                    style={{
                                        backgroundColor: "",
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginTop: "30px",
                                            color: colors.verde_1
                                        }}
                                    >
                                        <Col>
                                            <Label
                                                style={{
                                                    fontSize: "48px",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                Búscanos
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{ marginTop: "5px" }}
                                    >
                                        <Col>
                                            <p
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "#343A40",
                                                }}
                                            >
                                                Operadora Tzedaká, S.A.P.I. DE C.V.
                                                Calle Vicente Cortes Herrera 465
                                                Col Santa Ana
                                                C.P. 29090
                                                Tuxtla Gutiérrez, Chiapas
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{ marginTop: "5px", }}
                                    >
                                        <Col>

                                            <List
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "#343A40",
                                                }}
                                            >
                                                <li>
                                                    <Phone style={{ marginRight: "5px", height: "20px", width: "20px" }} />
                                                    <a href="tel:+52 961 346 5243 ">+52 961 346 5243 </a>
                                                </li>
                                                <li
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    <Mail style={{ marginRight: "5px", height: "20px", width: "20px", }} />
                                                    <a href="mailto:operadora@tzedaka.com.mx">Mándanos un correo a operadora@tzedaka.com.mx</a>
                                                </li>

                                            </List>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            :
                            <>
                                <Row
                                    style={{
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    <Col>
                                        <center>
                                            <List
                                                style={{
                                                    marginTop: "10px"
                                                }}
                                            >
                                                <li>
                                                    <Phone /> <a href="tel:+52 961 346 5243">Llámanos al +52 961 346 5243</a>
                                                </li>
                                                <li
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    <Mail /> <a href="tel:+52 961 346 5243">Llámanos al +52 961 346 5243</a>
                                                </li>
                                                <li
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    <Room style={{ marginRight: "5px", height: "20px", width: "20px", }} />
                                                    <a onClick={() => { this.setState({ isOpenModalMap: true, }) }}>Presiona aquí para ver el mapa</a>
                                                </li>
                                                <li
                                                    style={{ marginTop: "10px" }}
                                                >

                                                </li>
                                            </List>
                                        </center>
                                    </Col>
                                </Row>
                            </>
                    }

                </div>
                <div style={{ marginTop: "100px" }}>

                </div>
                <Modal
                    isOpen={this.state.isOpenModalMap}
                >
                    <ModalHeader
                        toggle={() => { this.setState({ isOpenModalMap: false }); }}
                    >
                        Como llegar.
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.orientation === 90 ?
                                <Row>
                                    <Col>
                                        <center>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3820.753553627079!2d-93.10200788513261!3d16.739144088470145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDQ0JzIwLjkiTiA5M8KwMDUnNTkuNCJX!5e0!3m2!1ses!2smx!4v1680218289312!5m2!1ses!2smx"
                                                width={400}
                                                height={300}
                                                style={{ border: 0 }}
                                                allowFullScreen
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                                title="Mapa"
                                            />
                                        </center>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "22px",
                                                color: colors.azul
                                            }}
                                        >
                                            Para poder ver el mapa rota tu teléfono a la izquierda o a la derecha, gracias.
                                        </Label>
                                    </Col>
                                </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button

                            style={{
                                backgroundColor: colors.verde_2,
                                boxShadow: "none",
                                borderColor: colors.verde_3,
                                color: colors.b,
                                fontWeight: "bold"
                            }}
                            onClick={() => { this.setState({ isOpenModalMap: false }); }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default Index;