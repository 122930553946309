import axios from "axios";
// import API from '../config/services.config';
import API from '../config/network.config';
import swal2 from 'sweetalert2';
import colors from "../config/colors.config";

// const urlApi = API + "/api-cliente";
const urlApi = API + "/cliente";


const mensajeErrorLocal = (error) => {

    let menssage = error.config.url + ": " + error.message


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensajeErrorServices = (error) => {

    let menssage = error


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const sendFormData = async (formData) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "post",
            url: urlApi + `/formulario-contacto-guardar`,
            data: {
                formData
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    return data

}

const ClientServices = {
    sendFormData
}

export default ClientServices;