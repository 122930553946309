import { useEffect } from "react";
import NavBarComputer from "./NavBarComputer";
import NavBarMovil from "./NavBarMovil";
// import userModel from "../models/user.model";

const NavBar = (props) => {
   
    useEffect(() => {
        // userModel.getUserCookies();
    }, []);

    return (
        <>
            {props.isDesktopDevice ?
                <NavBarComputer/>
                :
                <NavBarMovil/>
            }
        </>
    );
}

export default NavBar;