import axios from "axios";
import API from '../config/network.config';
import swal2 from 'sweetalert2';
import colors from "../config/colors.config";
// import moment from "moment/moment";
import Cookies from "universal-cookie";
import components from "../tools/components.tool";


const cookies = new Cookies();
const urlApi = API + "/solicitud-credito";
const mensajeErrorLocal = (error) => {

    let menssage = error.config.url + ": " + error.message


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensajeErrorServices = (error) => {

    let menssage = error


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {

    let menssage = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(() => {
        window.location.href = "/login";
    });
}

const guardarSolicitudCredito = async (solicitudCredito) => {

    let response;
    let data = null;
    
    try {
        response = await axios({
            method: "POST",
            url: urlApi + `/guardar`,
            data:{
                solicitudCredito
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const solicitudCreditoModel = {
    guardarSolicitudCredito,
}

export default solicitudCreditoModel;
