import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Index from "./page/Index";
import AvisoPrivacidad from "./page/AvisoPrivacidad";
// import Productos from "./page/Productos";
import QuienesSomos from "./page/QuienesSomos";
import Mision_Y_Vision from "./page/MisionVision";
import SolicitudCredito from "./page/SolicitudCredito";
import 'react-toastify/dist/ReactToastify.css';
/****************************************************/
import NotFound404 from "./page/template/NotFound404";
import EnContruccion from "./page/template/EnContruccion";

/*  */
import SIndex from "./page/SIndex";

function App() {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isDesktopDevice = !regexp.test(details); 
  // let isMobileDevice = !isDesktopDevice;
  return (  
    <Router>
      <Routes>          
        <Route exact path="/s" element={<Index isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/" element={<SIndex   />}/>   
        <Route exact path="/productos" element={<EnContruccion  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/quienes-somos" element={<QuienesSomos  isDesktopDevice={isDesktopDevice} />}/> 
        <Route exact path="/mision-y-vision" element={<Mision_Y_Vision  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/mision" element={<Mision_Y_Vision  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/vision" element={<Mision_Y_Vision  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/contacto" element={<EnContruccion  isDesktopDevice={isDesktopDevice} />}/>                    
        <Route exact path="/aviso-de-privacidad" element={<AvisoPrivacidad isDesktopDevice={isDesktopDevice}/>}/>  
        <Route exact path="/solicitud-credito" element={<SolicitudCredito isDesktopDevice={isDesktopDevice}/>}/>  
        <Route path='*'  element={<NotFound404 isDesktopDevice={isDesktopDevice} />}/> 
      </Routes>
    </Router>
  );
}

export default App;
